.contact-button {
	background: linear-gradient(270deg, #2AABEE 0%, #229ED9 100%);
	padding: 13px;
	border: none;
	border-radius: 5px;
}

.contact-button a {
	color: white;
}

.contact-button a:hover {
	color: white;
}

.contact-button_icon {
	margin-right: 10px;
}

.contact-button_text {
	color: white;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	letter-spacing: 1.33px;
	text-transform: uppercase;
}