.player__wrapper {
	padding: 30px;
	height: 50vh;
}

@media screen and (max-width: 992px){
	.player__wrapper {
		padding: 0;
		height: 30vh;
	}
}

@media screen and (max-width: 576px){
	.player__wrapper {
		height: 40vh;
	}
}
