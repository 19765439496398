.consultant-wrapper {
    padding: 24px;
}

.consultant-wrapper .section-title {
    text-align: left;
    margin-bottom: 1.2em;
}

.consultant-image__wrapper {
    text-align: center;
}

.consultant-image {
    border-radius: 50%;
    height: 8rem;
}

.consultant-name {
    text-align: left;
    font-weight: 500;
    font-size: 18px;
    line-height: 22px;
    color: #FF5D00;
}

.consultant-button_text {
    color: white;
    font-weight: 700;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 1.33px;
    text-transform: uppercase;
}

.consultant-button_icon {
    margin-right: 10px;
}

.ant-divider-horizontal.ant-divider-with-text-left::before {
    width: 0 !important;
}

.ant-divider-inner-text {
    padding: 0 1em 0 0 !important;
}

.ant-divider-horizontal.ant-divider-with-text-left::after {
    width: 100% !important;
}

.consultant-bottom-text {
    color: #A1A1A1;
}

.important-links-title {
    margin-top: 1em;
    margin-bottom: 0.5em !important;
}

.important-link {
    margin: 0;
    color: #656565;
}

.important-link a {
    text-decoration: underline;
    font-weight: 700;
}

@media screen and (max-width: 768px) {
    .consultant_personal-data {
        display: flex;
        flex-direction: column;
        flex-wrap: nowrap;
        align-items: center;
    }

    .ant-divider-inner-text {
        padding: 0 1em !important;
    }

    .consultant-divider {
        min-width: 60%;
        width: 60%;
        padding: 0 30% !important;
    }

    .consultant-wrapper {
        text-align: center;
    }

    .consultant-wrapper .section-title {
        text-align: center;
    }

    .consultant-wrapper .consultant-name {
        text-align: center;
    }
}