.consultant-card {
    display: flex;
    flex-direction: column;
    align-items: center;
}

.consultant-card h1 {
    font-size: 1.5rem;
    margin-bottom: 0;
}

.consultant-card > p {
    margin-bottom: 0;
}

.avatar {
    margin: 1rem!important;
}

.alert-message {
    margin-bottom: 1rem!important;
}

.order-consultation-button {
    box-shadow: 0px 0px 7px 0px rgba(50, 50, 50, 0.3);
}

.order-consultation-button span {
    font-weight: 500;
}