.lesson-description .section-title {
	text-align: left;
}

.ql-video {
	width: 100%;
	min-height: 300px;
}

@media screen and (max-width: 768px) {
	.lesson-description .section-title {
		text-align: center;
	}
}