.lessons-title {
    text-align: center;
    color: #FF5D00;
    text-transform: uppercase;
    font-family: 'Gilroy', 'Proxima Nova', sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 30px;
    line-height: 29px;
    width: 80%;
    margin: 0 auto;
}

.lessons-subtitle {
    text-align: center;
}

@media screen and (max-width: 768px) {
    .lessons-title {
        font-size: 20px;
        line-height: normal;
        width: 92%;
    }
}