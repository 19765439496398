.seconds-past {
	font-weight: 700;
	color: #FF5D00;
}

.timing-section .section-title {
	text-align: left;
}


@media screen and (max-width: 768px) {
	.timing-section .section-title {
		text-align: center;
	}
}