.gift-banner {
	background-image: url('../../../assets/images/gift-background.png');
}

.gift-content {
	display: flex;
	flex-direction: row;
	justify-content: center;
	align-items: center;
}

.gift-content {
	margin: 0 auto;
	text-align: center;
	width: 90%;
}

.gift-btn {
	min-height: 40px;
	height: fit-content;
	width: 150px;
	background: #FFFFFF;
	border: none;
	border-radius: 6px;
}

.gift-text-wrapper {
	display: flex !important;
	flex-flow: column;
	align-items: flex-start;
	justify-content: flex-start;
	width: 70%;
}

.gift-title {
	font-size: 24px;
	text-transform: uppercase;
	margin: 0 0 10px 0;
	font-style: normal;
	font-weight: 700;
	letter-spacing: 1.33px;
	color: #FFFFFF;
	line-height: 20px;
}

.gift-name {
	font-size: 18px;
	text-align: left;
	font-style: normal;
	font-weight: 700;
	letter-spacing: 1.33px;
	color: #FFFFFF;
	line-height: 20px;
}

.gift-link {
	color: #FF5D00;
	font-style: normal;
	font-weight: 700;
	font-size: 16px;
	line-height: 24px;
	text-transform: uppercase;
}

.gift-link:hover {
	color: #FF5D00;
}

@media screen and (max-width: 768px) {
	.gift-btn__wrapper {
		margin-bottom: 30px;
	}

	.gift-title,
	.gift-name {
		width: 100%;
		text-align: center;
	}
}