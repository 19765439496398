:root {
    --secondary-color: #FF5D00;
    --text-color: #656565;
    --main-content-width: 1100px;
}

html,
body,
main,
footer {
    background: hsla(44, 90%, 92%, 1);
    background: linear-gradient(180deg, hsla(44, 90%, 92%, 1) 0%, hsla(40, 96%, 91%, 1) 17%, hsla(42, 87%, 97%, 1) 100%);
    background: -moz-linear-gradient(180deg, hsla(44, 90%, 92%, 1) 0%, hsla(40, 96%, 91%, 1) 17%, hsla(42, 87%, 97%, 1) 100%);
    background: -webkit-linear-gradient(180deg, hsla(44, 90%, 92%, 1) 0%, hsla(40, 96%, 91%, 1) 17%, hsla(42, 87%, 97%, 1) 100%);
    filter: progid: DXImageTransform.Microsoft.gradient(startColorstr="#fdf3d8", endColorstr="#FEEFD2", GradientType=1);
    color: var(--text-color);
}

.ant-card {
    color: var(--text-color);
    height: auto;
}

.lesson-container {
    max-width: var(--main-content-width);
    margin: 0 auto;
    margin-top: 30px;
}


.lesson-content {
    margin-top: 30px;
    background-color: #fff;
}

.lesson-header {
    display: flex;
    justify-content: center;
    align-items: center;
    background-image: url('../assets/images/banner-background.png') !important;
    height: 100% !important;
    padding: 5px 0;
}

.lesson-header img {
    max-height: 80px !important;
}

.lesson-subtitle {
    color: #656565;
}

.nav-link {
    color: var(--secondary-color);
    font-size: 18px;
    font-weight: 900;
}

.nav-link__arrow {
    color: var(--secondary-color);
}

.section-title {
    color: var(--secondary-color);
    font-size: 18px;
    font-weight: 500;
    line-height: 20px;
    text-align: center;
}

.lesson-rating {
    margin-right: 20px;
}

.ant-layout-footer {
    background-color: transparent;
}

.border-top-orange {
    border-top: 2px solid #FAB188;
}

.border-bottom-orange {
    border-bottom: 2px solid #FAB188;
}

.gift-banner {
    margin-top: 20px;
}

@media screen and (max-width: 1100px) {
    .nav-link_prev {
        margin-left: 20px;
    }

    .nav-link_next {
        margin-right: 20px;
    }
}

.ant-image-img {
    width: auto;
}

@media screen and (max-width: 768px) {
    .nav-link {
        font-size: 14px;
        font-weight: normal;
    }
}

@media screen and (max-width: 420px) {
    .lesson-header {
        min-height: 40px;
    }

    .lesson-header img {
        max-height: 35px !important;
    }
}